import axios from 'axios';


const Fileread =async  (bruh) => {
  const formData = new FormData();
  formData.append('file', bruh);
// console.log(formData)
// console.log(bruh)
const res = await axios.post('https://esg-das.herokuapp.com//upload', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': 'https://esg-das.herokuapp.com//upload'
  }}
  ).then((e)=>{
    console.log(e);
  })
  .catch((e)=>{
    console.log(e)
  });
  // console.log(res.data.fileName);
// console.log("file uploaded")
const response = await axios.get('https://esg-das.herokuapp.com//text',)
console.log(response.data);
localStorage.setItem("esg", response.data);
return response.data;
}
export default Fileread;