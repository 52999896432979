import { useState } from "react";
import NoteContext from "./noteContext";

const NoteState = (props) => {
    const [state,setState] = useState(false);
    const [fetched,setFetched] = useState(false);
    const [esg,setEsg] = useState();
    const trueFetched = () => {
        setFetched(true)
    }
    const falseFetched = () => {
        setFetched(false)
    }
    const trueupdate = () => {
        setState(true);
    }
    const falseupdate = () => {
        setState(false);
    };
    const setesg = (bruh) => {
        setEsg(bruh)
    }
    return (
        <NoteContext.Provider value = {{state,trueupdate,falseupdate,esg,setesg, fetched, trueFetched, falseFetched}}>
            {props.children}
        </NoteContext.Provider>
    )
}
export default NoteState