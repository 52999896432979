import { React, useEffect, useState, useContext } from 'react';
import Legends from './legends';
import NoteContext from './noteContext';
// import fs from 'fs'
// const winkNLP = require('wink-nlp');
// const model = require('wink-eng-lite-model');

// lazy load the text
// load text upto 10 quieries in future
// update esg localStorage at every few interval
// import fetch from "node-fetch";
//  red,orange, yellow,green,blue,violet,purple,fuchsia,pink,slate,amber,lime,bg-lime-200,bg-green-400,bg-teal-400	,bg-teal-700,bg-cyan-400,bg-cyan-700
//TODO 
// create a legend Done
// remove classes Done
// extract Text
// const nlp = winkNLP(model);


const Textligh = () => {
    const a = useContext(NoteContext);
    // var txt ='Apple recently announced that it had spent $848 million on a 25-year purchase of solar power from First Solar in California. The deal makes Apple’s US operations 100 percent powered by renewable energy, and is the largest solar deal on record. The company also announced in February a $1.8 billion plan for the construction and operation of two data centers in Ireland and Denmark that will provide renewably powered Apple data to its European customers, just as it already does for those in the United States. And on April 16, Apple announced a partnership with SunPower to build two solar plants in China, to power its corporate and retail facilities in the region. According to its 2015 Environmental Responsibility Report, Apple has avoided about 750,000 tons of carbon dioxide emissions since 2011 with its various energy-saving measures. In the midst of a climate crisis fueled by the burning of fossil fuels, these are undeniably good moves. As such, Apple is deservedly receiving praise for these efforts at its US operations.'
    // var power = "Please use the sharing tools found via the share button at the top or side of articles. Copying articles to share with others is a breach of FT.com T&Cs and Copyright Policy. Email licensing@ft.com to buy additional rights. Subscribers may share up to 10 or 20 articles per month using the gift article service. More information can be found at . Alongside this demand, tech companies have adopted some of the world’s most ambitious climate targets. At a time when Big Tech is facing increasing political scrutiny about its growing economic power, the biggest companies are starting to achieve something that chimes with the high ideals they once espoused. As they become green themselves, they are also speeding up the transformation of the entire electricity system.  How this happened is often traced back to 2010, when Google signed its first wholesale clean energy deal, also known as a power purchase agreement. “Once one of them started, all the rest followed, they always look at each other, and they go ahead,” says Insunza. Long-term contracts to buy clean energy made sense for the big tech companies, because they build data centres that operate for decades. “They also had the financial capacity, so they could take the challenge,” he adds. Clean energy deals and climate goals are now a source of rivalry between the tech companies, with significant implications for the planet."
    // var bruh = txt.replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|")
    console.log("TESTajsjdhnciuasze08pw4utqo98wl4oayretoqcn4wlayerhoq297l4cytenhx")
    console.log(a.esg)
    // sessionStorage.setItem("DATA",a.esg)
    var localitem = localStorage.getItem('esg');

    if (localitem == null) {
        var power = "Please write  Text or upload a File to get the output";
    } else {
        power = localitem
    }
    // console.log("print power ",power)
    var bruhh = power.replace(/([.?!])\s*(?=[A-Z])/g, "$1|").split("|")
    console.log(bruhh)
    localStorage.setItem("BRUH", JSON.stringify(bruhh))
    // var bruhh = nlp.readDoc(localitem).sentences()
    const [labels, setlabels] = useState([]);
    useEffect(() => {
        localStorage.setItem("LABELS", labels)
        console.log("LABLES", labels)
    }, [labels])

    async function query(data) {
        // a.falseFetched()
        const response = await fetch(
            "https://api-inference.huggingface.co/models/nbroad/ESG-BERT",
            {
                // headers: { Authorization: "Bearer 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" },
                // headers: { Authorization: "Bearer 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" },
                // headers: { Authorization: "Bearer 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" },
                // headers: { Authorization: "Bearer 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" },
                // headers: { Authorization: "Bearer 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" },
                // headers: { Authorization: "Bearer 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" },
                // headers: { Authorization: "Bearer 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" },
                // headers: { Authorization: "Bearer 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" },
                // headers: { Authorization: "Bearer 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" },


                
                headers: { Authorization: "Bearer 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" },

                method: "POST",
                body: JSON.stringify(data),
            }
        );
        const result = await response.json();
        // console.log(result)
        localStorage.setItem("RESPONSE", JSON.stringify(result))
        a.trueFetched();
        return result;
    }
    // console.log(bruhh)
    useEffect(() => {
        bruhh.map((item, index) => (
            // async function func() {
            query(item).then((response) => {
                // console.log("!!!!!!!!!!!!DDDDDDDDDDDDDDD!!!!!!!!!1")

                // console.log(JSON.stringify(response));
                const bruh = response[0].sort(
                    function (a, b) {
                        return parseFloat(b['score']) - parseFloat(a['score']);
                    }
                )
                const bg_color = Legends[bruh[0]['label']]

                //   {<div className={bg_color}>{item}</div>}
                setlabels(labels => [...labels, [item, bruh[0]['score'], bg_color, bruh[0]['label']]])

            }).catch((e) => console.log(e))

            // }
        ))
    }, [])


    return (
        // <Fetchesg txtt={bruhh}/>
        labels.map((items, ele) => {
            console.log("ITEMS",items)
            const valid = items[1] > 0.7
            if(valid){
                sessionStorage.setItem(items[3], items[2])
            }
            const cn = (valid ? items[2] : null) + "  my-1.5 w-fit px-1.5 rounded-lg text-justify text-lg g-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-60 border border-gray-200 "
            return (
                // <div className={cn} key={ele}>{items[0]} {valid?items[3]:null}  {valid?items[1]:null} </div> 
                <div className={cn} key={items[0]}>{items[0]}</div>
            )
        })
    )
}

export default Textligh;
