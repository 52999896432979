import React, { useState, useCallback,useContext } from "react";
import { useForm } from "react-hook-form";
import { useDropzone } from 'react-dropzone'
import Fileread from "./Fileread";
// import Textligh from "./Texthigh";
import NoteContext from "./noteContext";
// prefer file over textbox Done
// textbox and file Done

export default function Modal(props) {
  const a = useContext(NoteContext)
  // var [showModal, setShowModal] = useState(props.showModal);
  // setShowModal(props.showModal)
  // console.log(props.modalshow)
  const [Textarea,setTextarea] = useState();
  const { register, handleSubmit } = useForm();
  // const [data, setData] = useState("");
  const [loading, setloading] = useState("Save Changes");
  const [acceptedFile, setacceptedFile] = useState()
  function checkFile(file) {
    var extension = file.substr((file.lastIndexOf('.') + 1));
    if (!/(pdf|txt|doc|docx)$/ig.test(extension)) {
      return false;
    }
    return true;
  }
  const onDrop = useCallback(acceptedFiles => {
    // register = acceptedFiles
    setacceptedFile(acceptedFiles[0])
    // console.log(checkFile(acceptedFiles[0].path))
    // Do something with the files
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  async function onSubmit(data) {
    // console.log(data)
    // console.log(Textarea,acceptedFile);
    if (acceptedFile!=null){
      // console.log(Textarea,acceptedFile)
    const filee = acceptedFile;
    // console.log(filee);
    // // setloading("Upload Complete");
    const filevalid = checkFile(filee.name);
    // console.log(filevalid);
    if (filevalid) {

      console.log(filee.name)
      const hi = await Fileread(filee)
      console.log("#$%^^^^^^^^^^^^^^^^^^^^66")
      console.log(hi)
      console.log(hi)
      window.location.reload();

    }
    
  }else if (Textarea!=null && acceptedFile==null) {
    // 
    localStorage.setItem("esg", Textarea);
      // console.log(Textarea)
      // setShowModal(false)
      window.location.reload();
      a.setesg(Textarea)
    }
    // ExtractText(filee)

  }
  return (
    <>
      {/* <button
        className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 "
        type="button"
        onClick={() => setShowModal(true)}
      >
        Upload file
      </button> */}
      {a.state ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto  w-screen h-screen z-50 outline-none focus:outline-none absolute "
          >
            <div className="relative w-11/12 h-11/12 my-6 mx-auto max-w-6xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Upload Text from the ESG Report
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => a.falseupdate()}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                  <textarea
      className ="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
      id="exampleFormControlTextarea1"
      rows="3"
      placeholder="ESG Report"
      value={Textarea}
      onChange={(e)=>{
        setTextarea(e.target.value);
      }}
    ></textarea>
                  </p>
                </div>
                <div className="flex justify-center items-center w-full px-2.5">
                  <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div {...getRootProps()}>
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>


                      </div>
                      <input
                        onChange={e => setacceptedFile(e.target.files[0])}
                        id="dropzone-file"
                        type="file"
                        className="hidden h-11/12 w-11/12"
                        accept=".docx,.pdf,.doc"

                      />
                      {isDragActive ?
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Drop the files here ...</span></p> :
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>}
                      <p className="text-xs text-gray-500 dark:text-gray-400">TXT, PDF, WORD DOCUMENT (MAX. 10MB)</p>
                    </div>
                  </label>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6  rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => a.falseupdate()}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {loading}
                    {/* <input type="submit"/> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}