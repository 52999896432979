import React, { useState, useContext, useEffect } from "react";
import Textligh from "./Texthigh";
import Legends from "./legends";
import Modal from './modal';
import NoteContext from "./noteContext";
import Cards from "./Cards";
import ESGCards from "./ESGCards";


export default function Display({forceUpdate}) {
    const [selected, setSelected] = useState("none")
    // const [e, setE] = useState(0)
    // const [s, setS] = useState(0)
    // const [g, setG] = useState(0)


    // useEffect(() => {
    //     const k = JSON.parse(localStorage.getItem("RESPONSE"))
    //     // const bruh = JSON.parse(localStorage.getItem("BRUH"))
    //     // const count  = bruh.length
    //     // console.log(s)
    //     // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
    //     // console.log(k)

    //     setE(e*0);
    //     setG(g*0);
    //     setS(s*0);
    //     if(k){

    //         k[0].forEach(element => {
    //             // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
    //             if (element.score > 0.7) {
    //                 console.log(element)
        
    //                 // console.log(Classes[element.label] == "g")
    //                 if(Classes[element.label] == "e"){
    //                     setE(e + 1)
    //                 }
    
    //                 else if(Classes[element.label] == "s"){
    //                     setS(s + 1)
    //                 }
    
    //                 else if(Classes[element.label] == "g"){
    //                     setG(g + 1)
    //                 }
    //             }
    //         });
    //     }
    // }, [localStorage.getItem("LABELS"), selected])

    useEffect(() => {
        
        sessionStorage.setItem("selected", selected)

    }, [selected])

    const a = useContext(NoteContext)
    // console.log(a)
    const [validKeys, setValidKeys] = useState([])

    
    useEffect(() => {
        const k = JSON.parse(localStorage.getItem("RESPONSE"))
        if (k) {
            console.log(k)
            k[0].forEach(element => {
                if (element.score > 0.7) {
                    sessionStorage.setItem(element.label, 1);
                    setValidKeys(validKeys.concat([element.label]))
                    // setTimeout(()=>{}, 10000)
                    console.log(element)
                }
            });
        }
        console.log(validKeys)
        console.log("##########################################")

        
    }, [a.state, a.fetched, localStorage.getItem("RESPONSE")])
    // calcESG()
    

    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
    // console.log(localStorage.getItem("RESPONSE"))
    return (
        <div>
            {a.state ?
                <Modal /> : null}

            <div className="flex flex-col h-screen m-1 p-4 ">
                <div className="basis-2/5 flex flex-col justify-between items-center col-span-4 shadow-md shadow-shadow-grey p-4 rounded-xl border border-gray-200 overflow-auto my-4">
                    <div className="" id="viewer">
                        <Textligh />
                    </div>
                    {/* {()=>Parse()} */}
                    {/* (()=>{getPageText()}) */}
                    <div className="flex flex-row w-full justify-center">

                        <button
                            className="bg-modal text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none m-1 w-1/6 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => { console.log(a.state); a.falseFetched(); a.trueupdate() }}
                        >
                            Upload text
                        </button>
                        <button
                            className="bg-modal text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none m-1 w-1/6 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => { localStorage.clear(); sessionStorage.clear(); window.location.reload() }}
                        >
                            Clear
                        </button>
                    </div>
                </div>
                {/* {console.log(Object.keys(Legends))} */}
                <Cards Legends={Legends} selected={selected}/>
                <ESGCards Legends={Legends} setSelected={setSelected} selected={selected} fetched={a.fetched}/>
                {/* <Fileread/> */}
            </div>

        </div>
    )
}