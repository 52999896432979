import React, { useEffect } from 'react';
import Display from './components/Display';
// import Modal from './components/modal';
// import NoteContext from './components/noteContext';
import NoteState from './components/noteState';
// import Fileread from './components/fileread';
export default function App() {

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear()
  }, [])
  return (
    <NoteState>
      <div>
        {/* <Modal/> */}
        {/* <Textligh/>  */}
        <Display  />
        {/* <ExtractText/> */}
        {/* <Fileread/> */}
      </div>
    </NoteState>
  )
}