const Classes = {
  GHG_Emissions: "e",
  Air_Quality: "e",
  Water_And_Wastewater_Management: "e",
  Physical_Impacts_Of_Climate_Change: "e",
  Waste_And_Hazardous_Materials_Management: "e",
  Ecological_Impacts: "s",
  Product_Quality_And_Safety: "s",
  Human_Rights_And_Community_Relations: "s",
  Customer_Welfare: "s",
  Labor_Practices: "s",
  Access_And_Affordability: "s",
  Customer_Privacy: "s",
  Business_Ethics: "s",
  Competitive_Behavior: "s",
  Employee_Health_And_Safety: "s",
  Employee_Engagement_Inclusion_And_Diversity: "s",
  Energy_Management: "e",
  Management_Of_Legal_And_Regulatory_Framework: "g",
  Product_Design_And_Lifecycle_Management: "g",
  Business_Model_Resilience: "g",
  Supply_Chain_Management: "g",
  Director_Removal: "g",
  Selling_Practices_And_Product_Labeling: "g",
  Systemic_Risk_Management: "g",
  Data_Security: "g",
  Critical_Incident_Risk_Management: "g",
};

export default Classes;
