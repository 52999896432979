import React, { useEffect, useState } from "react";
import Classes from "./classes";

const ESGCards = ({ Legends, setSelected, selected, fetched }) => {
    const [e, setE] = useState(0);
    const [s, setS] = useState(0);
    const [g, setG] = useState(0);

    // console.log(props)
    // const selected = "e"
    // useEffect(() => {
    //     sessionStorage.setItem("selected", selected)
    // }, [selected])
    // JSON.parse(localStorage.getItem("BRUH"));

    // const bruh = JSON.parse(localStorage.getItem("BRUH"));
    // const count = bruh.length;

    useEffect(() => {
        var x = [];
        Object.keys(Legends).map((items, ele) => {
            if (sessionStorage.getItem(items)) {
                x.push(Classes[items]);
                // x+=(Classes[items])

                // if(Classes[items] == 'e'){
                //     setE(e+1);
                // }
                // else if(Classes[items] == 's'){
                //     setS(s+1);
                // }

                // else if(Classes[items] == 'g'){
                //     setG(g+1);
                // }
            }
            // console.log("------------------------------------");
            // console.log(x);
            // console.log("------------------------------------");
        });

        const count = {};

        x.forEach(ele => {
            count[ele] = (count[ele] || 0) + 1;
        })
        setE(count["e"] || 0)
        setS(count["s"] || 0)
        setG(count["g"] || 0)


    }, [fetched, Legends]);

    useEffect(() => {
        var x = [];
        Object.keys(Legends).map((items, ele) => {
            if (sessionStorage.getItem(items)) {
                x.push(Classes[items]);
                // x+=(Classes[items])

                // if(Classes[items] == 'e'){
                //     setE(e+1);
                // }
                // else if(Classes[items] == 's'){
                //     setS(s+1);
                // }

                // else if(Classes[items] == 'g'){
                //     setG(g+1);
                // }
            }
            // console.log("------------------------------------");
            // console.log(x);
            // console.log("------------------------------------");
        });

        const count = {};

        x.forEach(ele => {
            count[ele] = (count[ele] || 0) + 1;
        })
        setE(count["e"] || 0)
        setS(count["s"] || 0)
        setG(count["g"] || 0)


    }, [selected, Legends]);
    return (
        <div className="p-2 ml-4 px-3 overflow-auto flex justify-around flex-row flex-wrap gap-2 static ">
            <div
                onClick={() => setSelected("e")}
                className={`${selected == "e" ? "bg-cyan-300" : null
                    } rounded-3xl px-6 h-28 w-1/4 m-1.5 text-3xl flex justify-center items-center flex-col bg-opacity-60 font-medium shadow-md shadow-shadow-grey border border-gray-200`}
            >
                <span className="text-xl p-2">Environment</span>
                {selected=="none"?null:e}
                {/* {e} */}
            </div>
            <div
                onClick={() => setSelected("s")}
                className={`${selected == "s" ? "bg-cyan-300" : null
                    } rounded-3xl px-6 h-28 w-1/4 m-1.5 text-3xl flex justify-center items-center flex-col bg-opacity-60 font-medium shadow-md shadow-shadow-grey border border-gray-200`}
            >
                <span className="text-xl p-2">Social</span>
                {selected=="none"?null:s}
                {/* {s} */}
            </div>
            <div
                onClick={() => setSelected("g")}
                className={`${selected == "g" ? "bg-cyan-300" : null
                    } rounded-3xl px-6 h-28 w-1/4 m-1.5 text-3xl flex justify-center items-center flex-col bg-opacity-60 font-medium shadow-md shadow-shadow-grey border border-gray-200`}
            >
                <span className="text-xl p-2">Governance</span>
                {selected=="none"?null:g}
                {/* {g} */}
            </div>
        </div>
    );
};

export default ESGCards;
