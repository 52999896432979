const Legends = {
    "Access_And_Affordability": "bg-red-300",
    "Air_Quality": "bg-red-500",
    "Business_Ethics": "bg-orange-300",
    "Business_Model_Resilience": "bg-orange-500",
    "Competitive_Behavior": "bg-yellow-300",
    "Critical_Incident_Risk_Management": "bg-yellow-500",
    "Customer_Privacy": "bg-green-300",
    "Customer_Welfare": "bg-green-500",
    "Data_Security": "bg-blue-300",
    "Director_Removal": "bg-blue-500",
    "Ecological_Impacts": "bg-violet-300",
    "Employee_Engagement_Inclusion_And_Diversity": "bg-purple-500",
    "Employee_Health_And_Safety": "bg-purple-300",
    "Energy_Management": "bg-pink-500",
    "GHG_Emissions": "bg-pink-300",
    "Human_Rights_And_Community_Relations": "bg-fuchsia-500",
    "Labor_Practices": "bg-fuchsia-300",
    "Management_Of_Legal_And_Regulatory_Framework": "bg-teal-300",
    "Physical_Impacts_Of_Climate_Change": "bg-slate-300",
    "Product_Design_And_Lifecycle_Management": "bg-amber-500",
    "Product_Quality_And_Safety": "bg-amber-300",
    "Selling_Practices_And_Product_Labeling": "bg-lime-500",
    "Supply_Chain_Management": "bg-lime-300",
    "Systemic_Risk_Management": "bg-teal-500",
    "Waste_And_Hazardous_Materials_Management": "bg-cyan-300",
    "Water_And_Wastewater_Management": "bg-cyan-500"
    }

export default Legends;