import React from 'react';
import Classes from './classes'
const Cards = ({Legends, selected}) => {
    return ( 
        <div className="p-2 rounded-lg ml-4 px-3 overflow-auto flex flex-row flex-wrap gap-2 static" data={0}>

        {/* <div className="... "> */}
        {Object.keys(Legends).map((items, ele) => {
            // console.log(items)
            const label = items.replace(/_/g, ' ')
            // const cn = Legends[items] + " rounded-full p-1 h- m-1.5 text-sm flex justify-center flex justify-center items-center bg-opacity-60 border border-gray-200 font-medium "
            if(Classes[items] == selected){

                return (
                    <div className={`${sessionStorage.getItem(items)? Legends[items] : null} rounded-full px-6 h-12 m-1.5 text-sm flex justify-center items-center bg-opacity-60  font-medium shadow shadow-shadow-grey`} key={items} >{label}</div>
                )
            }
            
        })
        }
        {/* </div> */}
    </div>

     );
}
 
export default Cards;